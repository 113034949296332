.notice-text {
    position: fixed;
    text-align: center;
    font-size: 2rem;
    font-style: italic;
    justify-content: center;
    background: rgba(255, 255, 255, .7);
    padding: 3px;
    width: 100%;
  }
