#roomBlocks {
  color: blue !important;
}

.resources {
    margin-bottom: 50px;
}

.maps {
    color: white;
}

.wg-link {
    font-size: 1rem;
    color: blue;
}

.wp-list{
    list-style:none;
    font-style: italic;
}

.map-icon {
    width: 20px;
    margin-right: 5px;
    position: relative;
    vertical-align: bottom;
}

.small-chrome {
    font-size: .8rem;
    list-style: none;
    padding-left: 22px;
    margin-bottom: 10px;
}