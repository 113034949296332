* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

.home,
.gen-info,
.working-groups,
.industry,
.contact,
.coming-soon {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.gen-info {
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #000;
  font-size: 75px;
}

.working-groups {
  /*background-image: url('');*/
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #000;
  font-size: 75px;
}

.industry {
  /*background-image: url('');*/
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #000;
  font-size: 75px;
}

.contact {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #000;
  font-size: 75px;
}

.coming-soon {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #000;
  font-size: 75px;
  text-align: center;
}
