body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

  .navbar {
    background: #000000;
    height: 80px;
    font-size: 1.2rem;
    top: 0;
    z-index: 999;
  }

  .navbar-container {
    display: inline-flex !important;
    justify-content: center;
    height: 80px;
  }

  .navbar-logo {
    color: #fff;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
  }

  .fa-typo3 {
    margin-left: 0.5rem;
    font-size: 1.8rem;
  }

  .nav-menu {
    display: inline-flex;
    grid-template-columns: repeat(4, auto);
    list-style: none;
    justify-content: center;
  }

  .nav-item {
    height: 80px;
    display: flex;
    justify-content: center;
    align-self: center;
    text-align: center;
  }

  .nav-links {
    color: #fff;
    text-decoration: none;
    padding: 1.5rem 1rem;
    height: 100%;
  }

  .nav-links:hover {
    border-bottom: 4px solid #fff;
    color: #fff;
    transition: all 0.2s ease-out;
  }

  .fa-bars {
    color: #fff;
  }

  .nav-links-mobile {
    display: none;
  }

  .menu-icon {
    display: none;
  }

  @media screen and (max-width: 576px) {
    .NavbarItems {
      position: relative;
    }

    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 90vh;
      position: absolute;
      top: 80px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
    }

    .nav-menu.active {
      background: #242222;
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
      padding-left: 0;
    }

    .nav-links {
      text-align: center;
      padding: 1rem;
      width: 100%;
      display: table;
    }

    .nav-links:hover {
      background-color: #fff;
      color: #242424;
      border-radius: 0;
    }

    .navbar-logo {
      position: absolute;
      top: 0;
      left: 0;
      -webkit-transform: translate(25%, 50%);
              transform: translate(25%, 50%);
    }

    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      -webkit-transform: translate(-90%, 45%);
              transform: translate(-90%, 45%);
      font-size: 1.8rem;
      cursor: pointer;
    }

    .fa-times {
      color: #fff;
      font-size: 2rem;
    }

    .nav-links-mobile {
      display: block;
      text-align: center;
      margin: 2rem auto;
      border-radius: 4px;
      width: 80%;
      text-decoration: none;
      font-size: 1.5rem;
      background-color: transparent;
      color: #fff;
      padding: 14px 20px;
      border: 1px solid #fff;
      transition: all 0.3s ease-out;
    }

    .nav-links-mobile:hover {
      background: #fff;
      color: #242424;
      transition: 250ms;
    }
  }

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}

.home,
.gen-info,
.working-groups,
.industry,
.contact,
.coming-soon {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.gen-info {
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #000;
  font-size: 75px;
}

.working-groups {
  /*background-image: url('');*/
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #000;
  font-size: 75px;
}

.industry {
  /*background-image: url('');*/
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #000;
  font-size: 75px;
}

.contact {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #000;
  font-size: 75px;
}

.coming-soon {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #000;
  font-size: 75px;
  text-align: center;
}

.notice-text {
    position: fixed;
    text-align: center;
    font-size: 2rem;
    font-style: italic;
    justify-content: center;
    background: rgba(255, 255, 255, .7);
    padding: 3px;
    width: 100%;
  }

:root {
  --primary: #fff;
}

.btn {
  padding: 8px 20px;
  border-radius: 2px;
  background-color: #fff !important;
  background-color: var(--primary) !important;
  color: #000000 !important;
  cursor: pointer;
  font-size: 20px !important;
}

.btn--primary {
  background-color: #fff;
  background-color: var(--primary);
  border: 1px solid #fff;
  border: 1px solid var(--primary);
}

.btn--outline {
  background-color: transparent;
  padding: 8px 20px;
  border: 1px solid #fff;
  border: 1px solid var(--primary);
  transition: all 0.3s ease-out;
  font-size: 20px;
}

.btn:hover {
  transition: all 0.3s ease-out;
  background: #eb3731 !important;
  border: none !important;
  color: #fff !important;
  transition: 250ms;
}

img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
  }

  .main-container {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    object-fit: contain;
  }

  .bigPlane {
        position: absolute;
        display: flex;
        top: 15%;
        left: 60%;
        width: auto;
        height: auto;
        z-index: 50;
  }

  .bigPlane img {
        height: 550px;
  }

.slide-in {
    animation: slide-in 2s forwards;
    -webkit-animation: slide-in 2s forwards;
    -webkit-animation-delay: .75s;
            animation-delay: .75s;
}

@keyframes slide-in {
    100% {
        -webkit-transform: translateX(-300%);
                transform: translateX(-300%);
        opacity: 0;
        display: none;
    }
}

@-webkit-keyframes slide-in {
    100% {
        -webkit-transform: translateX(-300%);
        opacity: 0;
        display: none;
    }
}

  .main-container > h1 {
    color: #fff;
    font-size: 100px;
    font-style: normal;
  }

  .weptac-title{
    background-color: rgba(0,0,0,.75);
    padding: 10px 50px;
    border-radius: 20px;
    text-align: center;
  }

  .main-container > p1, p2 {
    margin-top: 8px;
    /*color: #fff;*/
    color: blue;
    text-shadow: 0px 3px 0px #000000,
                 0px 14px 10px rgba(0,0,0,0.15),
                 0px 24px 2px rgba(0,0,0,0.1),
                 0px 34px 30px rgba(0,0,0,0.1);
    font-size: 32px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }

  .main-btns {
    margin-top: 32px;
  }

  .main-btns .btn {
    margin: 20px;
    width: 460px;
    min-height: 140px;
    box-shadow: none !important;
  }

  .reg-notice{
    font-size: 1rem;
    background-color: white;
    font-style: italic;
    padding: 0px 10px;
    box-shadow: 2px 2px 2px 3px black;
  }

  @media screen and (max-width: 960px) {
    .main-container > h1 {
      font-size: 70px;
      margin-top: -150px;
    }
  }

  @media screen and (max-width: 768px) {
    .main-container > h1 {
      font-size: 50px;
      margin-top: -100px;
    }

    .main-container > p1, p2 {
      font-size: 30px;
    }

    .main-btns {
      display: flex;
      flex-direction: column;
      text-decoration: none;
    }

    .btn {
      width: 100%;
    }
  }

.footer-container {
    background-color: #242424;
    padding: 2rem 0 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
}

/*.footer-subscription {*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    text-align: center;*/

/*    margin-bottom: 24px;*/
/*    padding: 24px;*/
/*    color: #fff;*/
/*}*/

/*.footer-subscription > p {*/
/*    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',*/
/*    'Lucida Sans', Arial, sans-serif;*/
/*}*/

/*.footer-subscription-heading {*/
/*    margin-bottom: 24px;*/
/*    font-size: 24px;*/
/*}*/

/*.footer-subscription-text {*/
/*    margin-bottom: 24px;*/
/*    font-size: 20px;*/
/*}*/

/*.footer-input {*/
/*    padding: 8px 20px;*/
/*    border-radius: 2px;*/
/*    margin-right: 10px;*/
/*    outline: none;*/
/*    border: none;*/
/*    font-size: 18px;*/
/*    margin-bottom: 16px;*/
/*    border: 1px solid #fff;*/
/*}*/

/*.footer-links {*/
/*    width: 100%;*/
/*    max-width: 1000px;*/
/*    display: flex;*/
/*    justify-content: center;*/
/*}*/

/*.footer-link-wrapper {*/
/*    display: flex;*/
/*}*/

/*.footer-link-items {*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    align-items: flex-start;*/
/*    margin: 16px;*/
/*    text-align: left;*/
/*    width: 160px;*/
/*    box-sizing: border-box;*/
/*}*/

/*.footer-link-items h2 {*/
/*    margin-bottom: 16px;*/
/*}*/

/*.footer-link-items > h2 {*/
/*    color: #fff;*/
/*}*/

/*.footer-link-items a {*/
/*    color: #fff;*/
/*    text-decoration: none;*/
/*    margin-bottom: 0.5rem;*/
/*}*/

/*.footer-link-items a:hover {*/
/*    color: #e9e9e9;*/
/*    transition: 0.3s ease-out;*/
/*}*/

/*.footer-email-form h2 {*/
/*    margin-bottom: 2rem;*/
/*}*/

/*.footer-input::placeholder {*/
/*    color: #b1b1b1;*/
/*}*/

/*!* Social Icons *!*/
/*.social-icon-link {*/
/*    color: #fff;*/
/*    font-size: 24px;*/
/*}*/

/*.social-media {*/
/*    max-width: 1000px;*/
/*    width: 100%;*/
/*}*/

/*.social-media-wrap {*/
/*    display: flex;*/
/*    justify-content: space-between;*/
/*    align-items: center;*/
/*    width: 90%;*/
/*    max-width: 1000px;*/
/*    margin: 40px auto 0 auto;*/
/*}*/

/*.social-icons {*/
/*    display: flex;*/
/*    justify-content: space-between;*/
/*    align-items: center;*/
/*    width: 240px;*/
/*}*/

/*.social-logo {*/
/*    color: #fff;*/
/*    justify-self: start;*/
/*    margin-left: 20px;*/
/*    cursor: pointer;*/
/*    text-decoration: none;*/
/*    font-size: 2rem;*/
/*    display: flex;*/
/*    align-items: center;*/
/*    margin-bottom: 16px;*/
/*}*/

/*.website-rights {*/
/*    color: #fff;*/
/*    margin-bottom: 16px;*/
/*}*/

/*@media screen and (max-width: 820px) {*/
/*    .footer-links {*/
/*        padding-top: 2rem;*/
/*    }*/

/*    .footer-input {*/
/*        width: 100%;*/
/*    }*/

/*    .btn {*/
/*        width: 100%;*/
/*    }*/

/*    .footer-link-wrapper {*/
/*        flex-direction: column;*/
/*    }*/

/*    .social-media-wrap {*/
/*        flex-direction: column;*/
/*    }*/
/*}*/

/*@media screen and (max-width: 768px) {*/
/*}*/
a {
    color: white;
}

.agenda-section {
  justify-content: space-between;
  display: flex;
}
.save-the-date{
    text-align: center;
}
h2 {
  padding-top: 50px;
}
.titles {
  font-size: 3rem;
  padding-top: 20px;
  text-align: left;
  flex: 1 1 auto;
}

.text-important {
  font-size: 1rem;
  text-align: left;
  font-style: italic;
  border-radius: 2px;
  color: red;
}

.subtitles {
  text-align: left;
  padding-bottom: 20px;
  padding-top: 10px;
  color: #5c5c5c;
  font-size: 1.5rem;
}

.agenda-text {
  font-size: 1rem;
  text-align: left;
}

.attendance-list {
  list-style: inherit;
  padding-bottom: 10px;
  padding-left: 30px;
}

details {
  padding-top: 20px;
  font-size: 20px;
}

details > summary {
  border: none;
  cursor: pointer;
  list-style: none;
}

details > p {
  margin: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 25px;
  font-size: 20px;
  color: #000000;
  list-style: circle;
}

details summary::-webkit-details-marker {
  display: none;
}

details summary::before {
  content: "+";
  color: #2e78a6;
}

details[open] summary::before {
  content: "- ";
  color: #2e78a6;
}

@media (max-width: 576px) {
  details > summary {
    border: none;
    cursor: pointer;
    list-style: none;
  }
}

.higlight {
  /* display: inline;
  float: left; */
  background-color: yellow;
}

.bold {
  font-weight: bold;
}

#spectrumDoc {
  color: blue !important;
}
#siteLink {
  color: blue !important;
}


.groups-container {
  justify-content: center;
  display: flex;
  vertical-align: text-top;
}

.columns {
  padding-top: 50px;
  flex: 1 1 auto;
}

.section-title {
  font-size: 3rem;
  text-align: left;
  vertical-align: top;
}

.subtitle-text {
  color: #2e78a6;
  padding-bottom: 10px;
  padding-top: 50px;
}

.wg-list{
  list-style: none;
  padding-top: 5px;
  font-size: 1rem;
  padding-left: 0px;
}

.list-cols {
  -webkit-column-count: 3;
          column-count: 3;
  overflow: auto;
}

.industry-info {
  justify-content: center;
  display: flex;
  padding-top: 50px;

}

.header {
  text-align: left;
  padding-bottom: 20px;
}

.text{
  font-size: 1.2rem;
}

.hilton-info {
  text-align: left;
  color: #5c5c5c;
  font-size: 1.5rem;
  padding-top: 0px;
}

.color {
    color: black;
}

#industryVendors {
  color: blue !important;
}

.contact-info {
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 50px;
}

.contact-title {
  font-size: 1rem;
  color: #5c5c5c;
}

p {
  font-size: 1.25rem;
}

#roomBlocks {
  color: blue !important;
}

.resources {
    margin-bottom: 50px;
}

.maps {
    color: white;
}

.wg-link {
    font-size: 1rem;
    color: blue;
}

.wp-list{
    list-style:none;
    font-style: italic;
}

.map-icon {
    width: 20px;
    margin-right: 5px;
    position: relative;
    vertical-align: bottom;
}

.small-chrome {
    font-size: .8rem;
    list-style: none;
    padding-left: 22px;
    margin-bottom: 10px;
}
