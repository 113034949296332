.agenda-section {
  justify-content: space-between;
  display: flex;
}
.save-the-date{
    text-align: center;
}
h2 {
  padding-top: 50px;
}
.titles {
  font-size: 3rem;
  padding-top: 20px;
  text-align: left;
  flex: 1 1 auto;
}

.text-important {
  font-size: 1rem;
  text-align: left;
  font-style: italic;
  border-radius: 2px;
  color: red;
}

.subtitles {
  text-align: left;
  padding-bottom: 20px;
  padding-top: 10px;
  color: #5c5c5c;
  font-size: 1.5rem;
}

.agenda-text {
  font-size: 1rem;
  text-align: left;
}

.attendance-list {
  list-style: inherit;
  padding-bottom: 10px;
  padding-left: 30px;
}

details {
  padding-top: 20px;
  font-size: 20px;
}

details > summary {
  border: none;
  cursor: pointer;
  list-style: none;
}

details > p {
  margin: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 25px;
  font-size: 20px;
  color: #000000;
  list-style: circle;
}

details summary::-webkit-details-marker {
  display: none;
}

details summary::before {
  content: "+";
  color: #2e78a6;
}

details[open] summary::before {
  content: "- ";
  color: #2e78a6;
}

@media (max-width: 576px) {
  details > summary {
    border: none;
    cursor: pointer;
    list-style: none;
  }
}

.higlight {
  /* display: inline;
  float: left; */
  background-color: yellow;
}

.bold {
  font-weight: bold;
}

#spectrumDoc {
  color: blue !important;
}
#siteLink {
  color: blue !important;
}

