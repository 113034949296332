.contact-info {
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 50px;
}

.contact-title {
  font-size: 1rem;
  color: #5c5c5c;
}

p {
  font-size: 1.25rem;
}
