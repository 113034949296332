.groups-container {
  justify-content: center;
  display: flex;
  vertical-align: text-top;
}

.columns {
  padding-top: 50px;
  flex: 1 1 auto;
}

.section-title {
  font-size: 3rem;
  text-align: left;
  vertical-align: top;
}

.subtitle-text {
  color: #2e78a6;
  padding-bottom: 10px;
  padding-top: 50px;
}

.wg-list{
  list-style: none;
  padding-top: 5px;
  font-size: 1rem;
  padding-left: 0px;
}

.list-cols {
  column-count: 3;
  overflow: auto;
}
