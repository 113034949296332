.industry-info {
  justify-content: center;
  display: flex;
  padding-top: 50px;

}

.header {
  text-align: left;
  padding-bottom: 20px;
}

.text{
  font-size: 1.2rem;
}

.hilton-info {
  text-align: left;
  color: #5c5c5c;
  font-size: 1.5rem;
  padding-top: 0px;
}

.color {
    color: black;
}

#industryVendors {
  color: blue !important;
}
