:root {
  --primary: #fff;
}

.btn {
  padding: 8px 20px;
  border-radius: 2px;
  background-color: var(--primary) !important;
  color: #000000 !important;
  cursor: pointer;
  font-size: 20px !important;
}

.btn--primary {
  background-color: var(--primary);
  border: 1px solid var(--primary);
}

.btn--outline {
  background-color: transparent;
  padding: 8px 20px;
  border: 1px solid var(--primary);
  transition: all 0.3s ease-out;
  font-size: 20px;
}

.btn:hover {
  transition: all 0.3s ease-out;
  background: #eb3731 !important;
  border: none !important;
  color: #fff !important;
  transition: 250ms;
}
