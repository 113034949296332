img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
  }

  .main-container {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    object-fit: contain;
  }

  .bigPlane {
        position: absolute;
        display: flex;
        top: 15%;
        left: 60%;
        width: auto;
        height: auto;
        z-index: 50;
  }

  .bigPlane img {
        height: 550px;
  }

.slide-in {
    animation: slide-in 2s forwards;
    -webkit-animation: slide-in 2s forwards;
    animation-delay: .75s;
}

@keyframes slide-in {
    100% {
        transform: translateX(-300%);
        opacity: 0;
        display: none;
    }
}

@-webkit-keyframes slide-in {
    100% {
        -webkit-transform: translateX(-300%);
        opacity: 0;
        display: none;
    }
}

  .main-container > h1 {
    color: #fff;
    font-size: 100px;
    font-style: normal;
  }

  .weptac-title{
    background-color: rgba(0,0,0,.75);
    padding: 10px 50px;
    border-radius: 20px;
    text-align: center;
  }

  .main-container > p1, p2 {
    margin-top: 8px;
    /*color: #fff;*/
    color: blue;
    text-shadow: 0px 3px 0px #000000,
                 0px 14px 10px rgba(0,0,0,0.15),
                 0px 24px 2px rgba(0,0,0,0.1),
                 0px 34px 30px rgba(0,0,0,0.1);
    font-size: 32px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }

  .main-btns {
    margin-top: 32px;
  }

  .main-btns .btn {
    margin: 20px;
    width: 460px;
    min-height: 140px;
    box-shadow: none !important;
  }

  .reg-notice{
    font-size: 1rem;
    background-color: white;
    font-style: italic;
    padding: 0px 10px;
    box-shadow: 2px 2px 2px 3px black;
  }

  @media screen and (max-width: 960px) {
    .main-container > h1 {
      font-size: 70px;
      margin-top: -150px;
    }
  }

  @media screen and (max-width: 768px) {
    .main-container > h1 {
      font-size: 50px;
      margin-top: -100px;
    }

    .main-container > p1, p2 {
      font-size: 30px;
    }

    .main-btns {
      display: flex;
      flex-direction: column;
      text-decoration: none;
    }

    .btn {
      width: 100%;
    }
  }
